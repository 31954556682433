@import url('https://fonts.googleapis.com/css?family=Homemade+Apple|Lora:400,700|Oswald:400,700&amp;subset=latin-ext');
html, body {
    height: 100%;
}
.overlay_rotate {
    display: none;
    position: fixed;
    top: 0;
    background-color: #333;
    color: #000;
    width: 100%;
    height: 100%;
    overflow: hidden !important;
    z-index: 777777777 !important;
}
.iconContainer {
    width: 300px;
    height: 200px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -150px;
    margin-top: -100px;
}
.img-center-5{
    position: absolute;
    top:calc(50% - 0px);
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc(100% - 30px);
    z-index: 1;
}
.sizer{overflow: hidden;}
.img-home-a{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 1rem;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}
.img-center-cuvees{
    position: absolute;
    top: calc(50% - -1px);
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc(100% - 0px);
    z-index: 1;
}
.img-center-pola{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(8deg);
    width: 70%;
    z-index: 1;
}
.z2{
    position: relative;
    z-index: 2;
}
.img-rotate{
    transform: rotate(-45deg);
    position: relative;
    z-index: 2;
}
.iconContainer p {
    display: inline-block;
    width: 100%;
    text-align: center;
    margin: 0;
    font-size: 20px;
    opacity: 0;
}
.phone {
    position: relative;
    left: 50%;
    margin-left: -80px;
    width: 0;
    height: 0;
    border: 0 solid #000;
    background: #a9a9a9;
    border-radius: 10px;
    box-sizing: border-box;
    margin-bottom:45px;
}
.phone i {
    text-align: center;
    width: 100%;
    line-height: 120px;
    font-size: 50px;
    color: #454545;
    opacity: 0;
}
@media only screen and (orientation: landscape) and (max-width: 820px) {
    .overlay_rotate {
        display: block;
        z-index:666;
    }
    .overlay_rotate .phone {
        -webkit-animation: sizeIncrease .5s forwards ease,  borderIncrease .5s .5s forwards ease,  rotateLeft .7s 1s forwards ease;
        animation: sizeIncrease .5s forwards ease,  borderIncrease .5s .5s forwards ease,  rotateLeft .7s 1s forwards ease;
    }
    .overlay_rotate .phone i {
        -webkit-animation: fadeIn .5s .8s forwards ease;
        animation: fadeIn .5s .8s forwards ease;
    }
    .overlay_rotate .iconContainer p {
        -webkit-animation: fadeIn .5s 1.3s forwards ease;
        animation: fadeIn .5s 1.3s forwards ease;
    }
}
@-webkit-keyframes sizeIncrease {
    0% {
        width: 10px;
        height: 0;
    }
    50% {
        width: 10px;
        height: 100px;
    }
    100% {
        width: 160px;
        height: 100px;
    }
}
@keyframes sizeIncrease {
    0% {
        width: 10px;
        height: 0;
    }
    50% {
        width: 10px;
        height: 100px;
    }
    100% {
        width: 160px;
        height: 100px;
    }
}
@-webkit-keyframes borderIncrease {
    100% {
        border-width: 10px 20px;
    }
}
@keyframes borderIncrease {
    100% {
        border-width: 10px 20px;
    }
}
@-webkit-keyframes fadeIn {
    100% {
        color: white;
        opacity: 1;
    }
}
@keyframes fadeIn {
    100% {
        color: white;
        opacity: 1;
    }
}
@-webkit-keyframes rotateLeft {
    100% {
        -webkit-transform: rotate(-90deg);
        transform: rotate(-90deg);
    }
}
@keyframes rotateLeft {
    100% {
        -webkit-transform: rotate(-90deg);
        transform: rotate(-90deg);
    }
}
body {
    font-family: 'Lora', serif;
    font-size: 15px;
    color: #443937;
    display: flex;
    flex-direction: column;
}
a, a:focus, a:hover, a.active {
    color: #443937;
    text-decoration: none;
}
footer {
    font-family: 'Oswald' !important;
    font-size: 1.2em;
}
.cc-color-override-647653678 .cc-btn {
    background-color: #443937 !important;
}
.triman {
    width: 55px;
}
.img-home {
    width: 100%;
    height: auto;
}
.container-fluid {
    flex: 1 0 auto;
}
footer {
    flex-shrink: 0;
}
.btn-outline-light {
    border: 1px solid #676869;
    border-radius: 30px;
    padding: .3em 4em;
    text-transform: uppercase;
    font-weight: bold;
    color: #676869;
}
@media screen { @media (min-width: 0px) {
    .fix-safari {
        flex: 1 !important;
    }
} }
.z-index-high {
    z-index: 7777;
}
.mt-15, .my-15 {
    margin-top: 6rem!important;
}
/* Verify age */
.verify {
    transition: all 700ms ease;
    background-color: #fff;
    z-index: 77777777;
    position: fixed;
    padding: 15px;
    opacity: 1;
    bottom: 0;
    right: 0;
    left: 0;
    top: 0;
}
.verify.off {
    opacity: 0;
    z-index: -7;
}
.verify img {
    max-width: 300px;
}
.verify p {
    max-width: 500px;
    margin: auto
}
.verify a {
    color: #000;
}
.verify label {
    font-weight: bold;
    color: #000;
}
.verify h1 {
    color: #000;
    font-weight: normal;
    text-shadow: none;
}
.btn.btn-verify {
    background-color: #000;
    border-color: #000;
    color: #fff !important;
    text-transform: uppercase;
    padding: .3em 2.5em;
    border-radius: 30px;
}
.btn-verify:hover, .btn-verify:active, .btn-verify:focus {
    border-color: #000;
}
/* Fin Verify age */
@media (max-width:1024px) {
    .btn-outline-light {
        padding: .3em 1.5em;
        font-size: 1.5em;
    }
}
.btn-outline-light:hover, .btn-outline-light:active, .btn-outline-light:focus {
    background-color: #676869;
    color: #fff;
}
.homemade {
    font-family: 'Homemade Apple', cursive;
    line-height: 1.6;
}
h2 {
    font-family: 'Homemade Apple', cursive;
    line-height: 1.6;
    font-size: 2.2em;
}
@media (max-width:1024px) {
    h2 {
        font-size: 1.4em;
    }
}
h3 {
    font-weight: bold;
    font-size: 1.7em;
}
.uppercase {
    text-transform: uppercase;
}
.burger {
    cursor: pointer;
    position: fixed;
    right: 1em;
    box-shadow: 0 0 50px rgba(0,0,0,.3);
    bottom: 1em;
    border-radius: 60px;
    height: 60px;
    background-color: #fff;
    z-index: 777;
    width: 60px;
    background: url('../images/home/burger.svg') 50% 50% no-repeat;
    background-size: 30px;
}
.desktop .burger {
    bottom: inherit;
    top: 1em;
    left: inherit;
    right: 1em;
}
.burger span {
    display: none;
}
.carousel-control-next:focus, .carousel-control-next:hover, .carousel-control-prev:focus, .carousel-control-prev:hover {
    z-index: 1000;
}
nav {
    transition: all 300ms ease;
    background-color: #fff;
    text-align: center;
    position: fixed;
    z-index: -777;
    height: 100%;
    width: 100%;
    opacity: 0;
    left: 0;
    top: 0;
}
nav.on {
    opacity: 1;
    z-index: 7777;
}
nav ul li {
    list-style-type: none;
}
.mb-6 {
    margin-bottom: 5rem;
}
.socials img {
    width: 50px;
    height: 50px;
}
.first {
    font-size: 1.7em;
}
.second {
    font-size: 1.2em;
}
ul {
    padding-left: 0;
}
.close {
    background: white url('../images/home/close.svg') 50% 50% no-repeat;
    background-size: 40%;
    opacity: 1 !important;
    box-shadow: 0 0 50px rgba(0,0,0,.3);
    cursor: pointer;
    position: fixed;
    right: 1em;
    bottom: 1em;
    border-radius: 60px;
    height: 60px;
    width: 60px;
}
.desktop .close {
    top: .7em;
    right: .7em;
}
.close span {
    display: none;
}
.carousel-control-next {
    width: inherit;
    opacity: 1;
    position: fixed;
    top: 6em;
    bottom: 6em;
}
.carousel-control-prev, .carousel-control-next {
    width: inherit;
    opacity: 1;
    position: fixed;
    top: 6em;
    bottom: 6em;
    z-index: 7777777;
}
.carousel-control-next .carousel-control-next-icon, .carousel-control-prev .carousel-control-prev-icon {
    background: #fff url('../images/home/arrow.svg') 50% 50% no-repeat;
    background-size: 15px;
    border-radius: 30px 0 0 30px;
    box-shadow: 0 0 50px rgba(0,0,0,.3);
    padding: .1em 3em .1em .1em;
    min-height: 50px;
}
.carousel-control-next:focus, .carousel-control-next:hover, .carousel-control-prev:focus, .carousel-control-prev:hover {
    opacity: 1;
}
.carousel-control-prev .carousel-control-prev-icon {
    transform: rotate(180deg);
}
.carousel-item {
    min-height: 100%;
}
.carousel-inner .container-fluid {
    padding: 0;
    min-height: 100%;
}
.carousel-indicators li {
    border-radius: 10px;
    height: 10px;
    opacity: 1;
    width: 10px;
    border: 1px solid #443937;
}
.carousel-indicators .active {
    background-color: #443937;
}
.carousel-indicators {
    bottom: -3em;
}
/* Home / slide 2 */
.passeport {
    opacity: 0;
    position: absolute;
    right: 6em;
    bottom: -4em;
    width: 20%;
    transition: opacity 200ms ease;
    z-index: 2;
}
.photo {
    opacity: 0;
    position: absolute;
    right: 8em;
    top: -13em;
    width: 25%;
    transition: opacity 200ms ease;
}
/* Home / slide 3 */
.diapo-h {
    opacity: 0;
    position: absolute;
    top: -13em;
    left: 2em;
    transition: opacity 200ms ease;
}
.diapo-b {
    opacity: 0;
    position: absolute;
    bottom: -4em;
    right: 0;
    transition: opacity 200ms ease;
    z-index: 2;
}
.camembert {
    opacity: 0;
    position: absolute;
    bottom: -4em;
    right: 0;
    transition: opacity 200ms ease;
}
.camembert-2 {
    opacity: 0;
    position: fixed;
    bottom: -4em;
    right: 0;
    transition: opacity 200ms ease;
}
.polaroid-art-h {
    opacity: 0;
    position: absolute;
    top: -15em;
    right: 3em;
    width: 30%;
    transition: opacity 200ms ease;
    z-index: 1;
}
/* Art du champagne / slide 1 */
.polaroid-art-1 {
    opacity: 0;
    position: absolute;
    top: -22em;
    left: -14em;
    transition: opacity 200ms ease;
}
.flute-droite {
    opacity: 0;
    position: absolute;
    top: 4em;
    right: -1em;
    transition: opacity 200ms ease;
    z-index: -1;
}
.pressoir {
    opacity: 0;
    position: fixed;
    bottom: -22em;
    z-index: -7;
    transition: opacity 200ms ease;
    left: 28vw;
}
.polaroid-art-1.on,
.flute-droite.on,
.pressoir.on {
    opacity: 1;
}
/* Art du champagne / slide 2 */
.polaroid-art-2 {
    transition: opacity 200ms ease;
    margin-left: 0;
    opacity: 0;
    bottom: 0;
    position: fixed;
    z-index: -7;
    max-width: 15vw;
}
.bouteille {
    transition: opacity 200ms ease;
    position: fixed;
    opacity: 0;
    right: -1em;
    top: -1em;
    z-index: -1;
    max-width: 25vw;
}
.formule {
    transition: opacity 200ms ease;
    position: fixed;
    opacity: 0;
    top: 0;
    left: 0;
}
.polaroid-art-2.on,
.bouteille.on,
.camembert.on,
.formule.on {
    opacity: 1;
}
.mb-moins-3 {
    margin-bottom: -5.5em;
    z-index: -7;
}
.on {
    opacity: 1;
}
.cursor {
    -webkit-animation: animcursor 1500ms infinite;
    animation: animcursor 1500ms infinite;
    position: relative;
    position: absolute;
    margin-left: 46%;
    width: 7px;
    top: 1em;
}
.mouse {
    width: 35px;
}
@keyframes animcursor {
    from {
        left: 0px;
        opacity: 1;
    }
    to {
        left: 20px;
        opacity: 0;
    }
}
@-webkit-keyframes animcursor {
    from {
        left: 0px;
        opacity: 1;
    }
    to {
        left: 20px;
        opacity: 0;
    }
}
@media (max-width:1900px) {
    .pressoir {
        width: 20%;
    }
}
@media (max-width:1024px) {
    .diapo-h {
        width: 30%;
    }
    .diapo-b {
        width: 30%;
    }
    .polaroid-art-h {
        width: 25%;
    }
    .camembert {
        width: 10%;
    }
    .polaroid-art-1 {
        width: 25%;
        top: 0;
    }
    .flute-droite {
        width: 10%;
    }
    .pressoir {
        width: 30%;
    }
    .bouteille {
        width: 25%;
    }
}
.fil {
    padding-bottom: 5em;
    min-height: 215px;
}
.fil:after {
    background: url('../images/home/fil.jpg') 50% 50% no-repeat;
    background-size: contain;
    content: '';
    position: absolute;
    display: block;
    width: 4vw;
    height: 5px;
    top: 1em;
    right: -1.5vw;
}
.entourage {
    display: none;
    position: absolute;
    max-width: 85%;
    z-index: 77;
    top: 0;
    transform: translateY(-33%);
}
.col-md-2 a {
    position: relative;
    z-index: 777;
}
.col-md-2:hover > .entourage {
    display: block;
}
#page {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

#contenu {
    flex: 1;
}
.cuvees {
    overflow-x: auto;
    overflow-y: hidden;
    position: relative;
    white-space: nowrap;
}
.polaroid {
    margin-left: -10%;
    z-index: -7;
}
::-webkit-scrollbar, .cuvees::-webkit-scrollbar {
    width: 5px;
}
::-webkit-scrollbar-track, .cuvees::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #ccc;
    border-radius: 10px;
}
::-webkit-scrollbar-thumb, .cuvees::-webkit-scrollbar-thumb {
    background: #6c757d;
    border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover, .cuvees::-webkit-scrollbar-thumb:hover {
    background: #6c757d;
}
.cuvees .mt-5 {
    width: 25%;
    display: inline-block;
}
.cuvees .mt-5 img {
    transition: all 200ms ease;
    filter: grayscale(1);
    opacity: .5;
}
.cuvees .mt-5:hover img {
    filter: none;
    opacity: 1;
}
.cuvees .mt-5 .btn {
    opacity: 0;
}
.cuvees .mt-5:hover .btn {
    opacity: 1;
}
.path:hover {
    stroke-dasharray: 1000;
    stroke-dashoffset: 1000;
    animation: dash 5s linear alternate infinite;
}
.notes {
    background: url('../images/notes.png') 50% 50% no-repeat;
    background-size: contain;
    padding: 4em 1em 0 1em;
}
.ico-oeil, .ico-nez, .ico-bouche, .ico-accord {
    max-width: 40px;
    max-height: 30px;
    cursor: pointer;
    z-index: 77;
    position: relative;
}
.entourage_notes {
    opacity: 0;
    transition: all 300ms ease;
    position: absolute;
    top: -1.5em;
    left: .7em;
    z-index: 7;
    width: 81%;
}
.ico-oeil.on + .entourage_notes,
.ico-nez.on + .entourage_notes,
.ico-bouche.on + .entourage_notes,
.ico-accord.on + .entourage_notes {
    opacity: 1;
}
.ico-bouche {
    margin-top: .5em;
}
.oeil.off,
.nez.off,
.bouche.off,
.accord.off {
    position: absolute;
    opacity: 0;
    z-index: 7;
    transition: all 300ms ease;
    max-width: 90%;
    max-height: 135px;
    /*overflow-y: scroll;*/
    margin-left: 0;
}
.oeil.on,
.nez.on,
.bouche.on,
.accord.on {
    position: absolute;
    opacity: 1;
    z-index: 77;
    max-width: 90%;
    max-height: 135px;
    /*overflow-y: scroll;*/
    margin-left: 0;
}
.notes-txt {
    min-height: 200px;
}
.mentions ul li {
    margin-left: 2em;
}
.mentions ul li ul li {
    margin-left: 2em;
}
footer .ligne1 {
    background-color: #f6f6f6;
    color: #000;
    font-size: .8em;
    /*text-transform: uppercase;*/
    padding-top: .5em;
    margin-top: 3em;
    z-index: 777;
    position: relative;
}
footer .ligne2 {
    color: #fff;
    background-color: #443937;
}
footer .ligne2 .socials img {
    width: 40px;
    height: 40px;
    margin: 0 .5em;
}
footer .ligne2 ul {
    list-style-type: none;
    font-weight: 700;
    padding-left: 0;
    margin-bottom: 0;
}
footer .ligne2 a {
    color: #fff;
}
@media (min-width:2350px) {
    .bouteille, .polaroid-art-2, .pressoir, .polaroid-art-1, .diapo-h, .diapo-b  {
        width: 25%;
    }
    .camembert, .formule, .flute-droite {
        width: 10%;
    }
    .photo, .diapo-h, .polaroid-art-h {
        top: -13em;
    }
    .polaroid-art-2 {
        bottom: 12em;
    }
}


@media (min-width: 768px) and (max-width: 991px) {
    /* Show 4th slide on md if col-md-4*/
    .cuvees-carou .carousel-inner .active.col-md-4.carousel-item + .carousel-item + .carousel-item + .carousel-item {
        position: absolute;
        top: 0;
        right: -33.3333%;  /*change this with javascript in the future*/
        z-index: -1;
        display: block;
        visibility: visible;
    }
}
@media (min-width: 576px) and (max-width: 768px) {
    /* Show 3rd slide on sm if col-sm-6*/
    .cuvees-carou .carousel-inner .active.col-sm-6.carousel-item + .carousel-item + .carousel-item {
        position: absolute;
        top: 0;
        right: -50%;  /*change this with javascript in the future*/
        z-index: -1;
        display: block;
        visibility: visible;
    }
}
@media (min-width: 576px) {
    .cuvees-carou .carousel-item {
        margin-right: 0;
    }
    /* show 2 items */
    .cuvees-carou .carousel-inner .active + .carousel-item {
        display: block;
    }
    .cuvees-carou .carousel-inner .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left),
    .cuvees-carou .carousel-inner .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left) + .carousel-item {
        transition: none;
    }
    .cuvees-carou .carousel-inner .carousel-item-next {
        position: relative;
        transform: translate3d(0, 0, 0);
    }
    /* left or forward direction */
    .cuvees-carou .active.carousel-item-left + .carousel-item-next.carousel-item-left,
    .cuvees-carou .carousel-item-next.carousel-item-left + .carousel-item,
    .cuvees-carou .carousel-item-next.carousel-item-left + .carousel-item + .carousel-item {
        position: relative;
        transform: translate3d(-100%, 0, 0);
        visibility: visible;
    }
    /* farthest right hidden item must be also positioned for animations */
    .cuvees-carou .carousel-inner .carousel-item-prev.carousel-item-right {
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        display: block;
        visibility: visible;
    }
    /* right or prev direction */
    .cuvees-carou .active.carousel-item-right + .carousel-item-prev.carousel-item-right,
    .cuvees-carou .carousel-item-prev.carousel-item-right + .carousel-item,
    .cuvees-carou .carousel-item-prev.carousel-item-right + .carousel-item + .carousel-item {
        position: relative;
        transform: translate3d(100%, 0, 0);
        visibility: visible;
        display: block;
        visibility: visible;
    }
}
/* MD */
@media (min-width: 768px) {
    /* show 3rd of 3 item slide */
    .cuvees-carou .carousel-inner .active + .carousel-item + .carousel-item {
        display: block;
    }
    .cuvees-carou .carousel-inner .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left) + .carousel-item + .carousel-item {
        transition: none;
    }
    .cuvees-carou .carousel-inner .carousel-item-next {
        position: relative;
        transform: translate3d(0, 0, 0);
    }
    /* left or forward direction */
    .cuvees-carou .carousel-item-next.carousel-item-left + .carousel-item + .carousel-item + .carousel-item {
        position: relative;
        transform: translate3d(-100%, 0, 0);
        visibility: visible;
    }
    /* right or prev direction */
    .cuvees-carou .carousel-item-prev.carousel-item-right + .carousel-item + .carousel-item + .carousel-item {
        position: relative;
        transform: translate3d(100%, 0, 0);
        visibility: visible;
        display: block;
        visibility: visible;
    }
}
/* LG */
@media (min-width: 991px) {
    /* show 4th item */
    .cuvees-carou .carousel-inner .active + .carousel-item + .carousel-item + .carousel-item {
        display: block;
    }
    .cuvees-carou .carousel-inner .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left) + .carousel-item + .carousel-item + .carousel-item {
        transition: none;
    }
    /* Show 5th slide on lg if col-lg-3 */
    .cuvees-carou .carousel-inner .active.col-lg-3.carousel-item + .carousel-item + .carousel-item + .carousel-item + .carousel-item {
        position: absolute;
        top: 0;
        right: -25%;  /*change this with javascript in the future*/
        z-index: -1;
        display: block;
        visibility: visible;
    }
    /* left or forward direction */
    .cuvees-carou .carousel-item-next.carousel-item-left + .carousel-item + .carousel-item + .carousel-item + .carousel-item {
        position: relative;
        transform: translate3d(-100%, 0, 0);
        visibility: visible;
    }
    /* right or prev direction //t - previous slide direction last item animation fix */
    .cuvees-carou .carousel-item-prev.carousel-item-right + .carousel-item + .carousel-item + .carousel-item + .carousel-item {
        position: relative;
        transform: translate3d(100%, 0, 0);
        visibility: visible;
        display: block;
        visibility: visible;
    }
}

.carou-margin-responsive {
    margin-top: 0;
}
@media (orientation: portrait) and (max-height: 1366px) {
    .carou-margin-responsive {
        margin-top: 4vh;
    }
}
@media (min-height: 1440px) {
    .carou-margin-responsive {
        margin-top: 4vh;
    }
}
@media (min-height: 1800px) {
    .carou-margin-responsive {
        margin-top: 8vh;
    }
}
@media (min-height: 2160px) {
    .carou-margin-responsive {
        margin-top: 12vh;
    }
}
form input, form textarea {
    width: 100%;
    margin: 1.2em 0;
}
input[type="text"], input[type="email"], input[type="tel"], textarea, select {
    display: block;
    border: 1px solid #fff;
    border-bottom: 1px solid #a7a7a7;
    color: #7d7d7d;
}
input[type="submit"] {
    font-family: 'Lora', serif;
    width: unset;
    border: 1px solid #858687;
    background-color: white;
    padding: .3em 6em;
    text-transform: uppercase;
    border-radius: 20px;
    font-weight: bold;
    color: #443937;
}
.contact-1 {
    position: absolute;
    bottom: 0;
    left: 0;
    max-width: 20vw;
    z-index: -1;

}
.contact-2 {
    position: absolute;
    right: 0;
    top: 25%;
    max-width: 20vw;
    z-index: -1;
}
.g-recaptcha { visibility: hidden; height: 0; }
